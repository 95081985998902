import { inject, Injectable } from '@angular/core';
import { AlternateVersionInterface } from './alternate-version.interface';
import { GlobalStore } from '../../store/global.store';
import { DOCUMENT, Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { SettingsService } from '../settings/settings.service';
import { UrlHelperService } from '../url/url-helper.service';

@Injectable({ providedIn: 'root' })
export class AlternateVersionService {
  store = inject(GlobalStore);
  document = inject(DOCUMENT);
  urlHelperService = inject(UrlHelperService);
  location = inject(Location);
  router = inject(Router);
  settingsService = inject(SettingsService);
  translocoService = inject(TranslocoService);

  update(versions: AlternateVersionInterface[]): any {
    this.updateAlternateVersionsInHtmlHead(versions);
    return this.store.setAlternateLink(versions);
  }

  routeToVersion(language: string) {
    this.translocoService.setActiveLang(language);
    let version = this.store.alternateLinks().find((v) => v.key === language);

    if (!version) {
      version = this.getSimple().find((v) => v.key === language);
    }

    this.settingsService.update({ language: version!.key });

    this.router.navigate([version!.url]).then(() => {
      window.location.reload();
    });
  }

  updateAlternateVersionsInHtmlHead(versions: AlternateVersionInterface[]) {
    // remove existing alternate links
    const existingAlternateLinks = this.document.querySelectorAll('link[rel="alternate"]');
    existingAlternateLinks.forEach((alternateLink) => alternateLink.remove());

    // create new alternate links
    versions.forEach((version) => {
      if (version.key === environment.defaultLocale) {
        this.createAlternateVersionElement('x-default', version.url);
      }
      this.createAlternateVersionElement(version.key, version.url);
    });
  }

  private createAlternateVersionElement(hreflang: string, href: string) {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hreflang', hreflang);
    this.document.head.appendChild(link);
    link.setAttribute('href', this.urlHelperService.enforceAbsoluteUrl(href));
  }

  /**
   * Replaces the language parameter in the current url
   */
  getSimple(): AlternateVersionInterface[] {
    return environment.availableLang.map(
      (lang: string) =>
        ({
          id: lang,
          key: lang,
          title: lang.toUpperCase(),
          url: decodeURI(this.location.path())
            .replace(/^\/[a-z]+\//, `/${lang}/`)
            .replace(/^\/[a-z]+[^/]$/, `/${lang}`),
        }) as AlternateVersionInterface,
    );
  }
}
