import { InjectionToken, Injector, Type } from '@angular/core';
import { firstValueFrom, Subject } from 'rxjs';

/**
 * Takes care of DI for non Injectables
 */
export class InjectorClass {
  /**
   * Provides injector for non DI components
   */
  private static injector: Injector | undefined = undefined;

  /**
   * Ready listener which resolves, as soon as
   * the Injector is available
   */
  private static ready: Subject<undefined> = new Subject<undefined>();

  /**
   * Manual injection with correct type
   */
  public static inject<T>(token: Type<T> | InjectionToken<T>, context?: any): T | undefined {
    if (!InjectorClass.injector) {
      // tslint:disable-next-line:no-console
      console.warn(
        `
// Inject before being ready. Add InjectorClass to the first possible place. E.g.
export class CoreModule {
  constructor(injector: Injector) {
    InjectorClass.set(injector);
  }
}`,
        token,
        context,
      );

      return undefined;
    }

    if ((InjectorClass.injector as any)._destroyed) {
      console.log('Injector already destroyed');
      return undefined;
    }

    return InjectorClass.injector.get<T>(token);
  }

  /**
   * Manual injection with correct type
   */
  public static async waitAndInject<T>(token: Type<T> | InjectionToken<T>): Promise<T> {
    await firstValueFrom(InjectorClass.ready);

    return InjectorClass.injector!.get<T>(token);
  }

  /**
   * Set the injector and mark it ready
   */
  public static set(injector: Injector): void {
    InjectorClass.injector = injector;
    InjectorClass.ready.next(undefined);
    InjectorClass.ready.complete();
  }
}
